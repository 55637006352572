import { h, Component } from 'preact';
import Header from '../components/Header'

export default class Contact extends Component {

	render() {
		return(
			<div class='contactPage'>
				<div class='headerExtension'>
					<Header />
					<div class='blur' />
					<div class='text'>
						<div class='topText'>Get in touch with us today</div>
						<p>
							Our courteous support staff are available over the phone and email, for convenience we offer a contact form available below.
						</p>
						<div class='callUs'>Call us at</div>
						<div class='phone'><a href='tel:18004844955'>1-800-484-4955</a></div>
					</div>
				</div>
				{/*<form class='forming' action='//www.enformed.io/i8932wka' method='post'>*/}
				{/*	<div class='inputWrapper'>*/}
				{/*		<input name='fullName' required placeholder='Your Full Name' />*/}
				{/*		<input name='email' required title='Email Address' placeholder='Your Email' type='email' inputMode='email' />*/}
				{/*		<input name='phone' required pattern='[0-9]{10,11}' title='Phone Number' placeholder='Your Phone' type='tel' inputMode='tel' />*/}
				{/*	</div>*/}
				{/*	<div class='textArea'>*/}
				{/*		<textarea name='message' required placeholder='Your Message...' />*/}
				{/*	</div>*/}
				{/*	<div class='submitWrapper'>*/}
				{/*		<button class='button' type='submit'>Send</button>*/}
				{/*	</div>*/}
				{/*</form>*/}
			</div>
		);
	}
}