import { h, Component } from 'preact';
import { Link } from 'preact-router/match';

import Logo from '../images/png/Logo.png';

export default class Header extends Component {
	render() {
		return (
			<header>
				<div class='wrapper'>
					<div class='logo'>
						<Link href='/'>
							<img src={Logo} alt='PosXperts logo' />
						</Link>
					</div>
					<div class='navContainer'>
						<nav>
							<ul>
								<li>
									<Link activeClassName='active' href='/'>
										Home
									</Link>
								</li>
								<li>
									<Link
										activeClassName='active'
										path='/pointofsale'
										href='/pointofsale'
									>
										Point of Sale
									</Link>
								</li>
								<li>
									<Link activeClassName='active' href='/datamanegment'>
										Data Management
									</Link>
								</li>
								<li>
									<Link activeClassName='active' href='/bestseller'>
										Best Seller
									</Link>
								</li>
								<li>
									<Link activeClassName='active' href='/techsupport'>
										Tech Support
									</Link>
								</li>
								<li>
									<Link activeClassName='active' href='/contact'>
										Contact Us
									</Link>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</header>
		);
	}
}
