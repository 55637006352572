import { h, Component } from 'preact';
import Header from '../components/Header';

export default class EPSG extends Component {
	render() {
		return (
			<div class='merchantsolutionsPage'>
				<div class='headerExtension'>
					<Header />
					<div class='blur' />

					<div class='text'>
						<div class='topText'>EPSG Merchant Solutions</div>
						<p>Leading provider of integrated payment solutions</p>
					</div>
				</div>
				<div class='info'>
					<div class='text'>
						<div class='topTitle'>
							Epsg Is Our Recommended Merchant Processor.
						</div>
						<div class='lists'>
							<ul>
								<li>Premier Payment Processor</li>
								<li>Personalized Consulting Services</li>
								<li>Payment Technology Integration Experts</li>
								<li>Traditional, Mobile & Virtual Terminals</li>
								<li>POS & Smart Terminal Solutions</li>
								<li>Electronic Invoicing</li>
							</ul>
							<ul>
								<li>PCI & EMV Compliant Equipment</li>
								<li>Fully Disclosed Pricing Structure</li>
								<li>Next Day Funding</li>
								<li>Merchant Cash Advance</li>
								<li>Live 24/7 Customer Support</li>
								<li>Recurring Billing</li>
							</ul>
						</div>
					</div>
				</div>
				<div class='info'>
					<div class='text'>
						<div class='topTitle'>
							Fully Integrated Gateway Solutions For Your Business
						</div>
						<div class='lists'>
							<ul class='longList'>
								<li>
									<span>Online Shopping Cart</span>: A critical component of any
									e-commerce solution, EPSG’s payment gateways allow you to add
									a shopping cart to your website where customers can easily
									select the items they want and pay online via a secure
									gateway.
								</li>
								<li>
									<span>Electronic Invoicing</span>: Collect fees faster and
									eliminate paper statements. These easy-to-use invoicing
									solutions integrate seamlessly with our payment gateway and
									helps your business increase operational efficiencies.
								</li>
								<li>
									<span>Mobile Solutions</span>: Accept credit and debit cards
									anywhere via your phone or tablet in a secure mobile
									environment with unmatched performance.
								</li>
								<li>
									<span>Recurring Billing</span>: Keep all your
									clients’ information a secured centralized database and bill
									them automatically.Create specialized payment plans or simple
									monthly payments, it’s up to you.
								</li>
								<li>
									<span>EMV Compliant Terminals</span>: Accept EMV chip
									compliant cards on all card present transactions.
								</li>
							</ul>
						</div>
					</div>
				</div>
				<hr />
				<div class='banner'>
					<p>Get in touch with us today.</p>
					<div class='buttonWrapper'>
						<a class='button' href='/contact'>
							Contact Us
						</a>
					</div>
				</div>
			</div>
		);
	}
}
