import { h, Component } from 'preact';
import Header from '../components/Header'

import bottleImage from '~images/svg/bottle.svg';
import smokingImage from '~images/svg/smoking.svg';
import pumpImage from '~images/svg/pump.svg';
import clothsImage from '~images/svg/clothes.svg';

export default class Hardware extends Component {

	render() {
		return (
			<div class='hardwarePage'>
				<div class='headerExtension'>
					<Header />
					<div class='text'>
						<div class='topText'>Hardware that doesn't slow down</div>
						<p>
							We will provide you with the most appropriate hardware to suit your business.
						</p>
					</div>
				</div>
				<div class='retail'>
					<div class='content'>
						<div class='title'>Retail Package</div>
						<div class='suitable'>
							<div className='for'>Suitable For</div>
							<div class='options'>
								<div class='item'>
									<div class='image'>
										<img src={bottleImage} alt='' />
									</div>
									<span>Liquor Shop</span>
								</div>
								<div class='item'>
									<div class='image'>
										<img src={smokingImage} alt='' />
									</div>
									<span>Smoke Shop</span>
								</div>
								<div class='item'>
									<div class='image'>
										<img src={pumpImage} alt='' />
									</div>
									<span>Convenience Store</span>
								</div>
								<div class='item'>
									<div class='image'>
										<img src={clothsImage} alt='' />
									</div>
									<span>Clothing Store</span>
								</div>
							</div>
						</div>
						<div class='text'>
							<div class='startingAt'>Starting at $2200</div>
							<p>This Point of Sale package is suitable for any form of retail. Our services are fully customizable to your business. Please give us a call for large purchases</p>
						</div>
					</div>
				</div>
				<div class='restaurant'>
					<div class='content'>
						<div class='title'>Restaurant Package</div>
						<div class='packages'>
							<div class='package'>
								<div class='name'>Basic</div>
								<div class='startingAt'>Starting at $2500</div>
								<p>Our Basic package provides everything your restaurant would need for efficient service. </p>
							</div>
							<div class='package'>
								<div class='name'>Tablet Based</div>
								<div class='startingAt'>Starting at $2700</div>
								<p>Our Tablet Based package is an upgrade that includes a Microsoft Surface tablet, alloying you to be able to interface easily with your POS system.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

}