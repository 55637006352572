import { h } from 'preact';

import { Link } from 'preact-router';
import Header from '../components/Header';
import ComputeSled from '../images/png/ComputeSled.png';
import RackServer from '../images/png/RackServer.png';

const DataManegment = () => {
	return (
		<div class='datamanagement'>
			<div class='headerExtension'>
				<Header />
				<div class='about'>
					<h1>Importance Of Data Management</h1>
					<p>
						Data increasingly is seen as a corporate asset that can be used to
						make better-informed business decisions, improve marketing
						campaigns, optimize business operations and reduce costs, all with
						the goal of increasing revenue and profits. But a lack of proper
						data management can saddle organizations with incompatible data
						silos, inconsistent data sets and data quality problems that limit
						their ability to run business intelligence and analytics
						applications or, worse, lead to faulty findings.
					</p>
				</div>
				<div class='test1'>
					<p>
						Data management is the practice of collecting, organizing, and
						accessing data to support productivity, efficiency, and
						decision-making.
					</p>
					<img src={ComputeSled} />
				</div>
				<div class='test2'>
					<p>
						Our Xperts know what you need. We can help you to install server for
						businesses of all sizes, arm you with the best and modern
						equipments, and we also can maintain everything for you.
					</p>
					<img src={RackServer} />
				</div>
				<div class='contact'>
					<p>For Quotes Please Call Us At</p>
					<Link href='tel:+18004844955'>1-800-484-4955</Link>
				</div>
			</div>
		</div>
	);
};

export default DataManegment;
