import {h, Component} from 'preact';
import {Link} from "preact-router";

export default class  About extends Component {

	render() {
		return (
			<div class='aboutPage'>
				<div class='headerExtension'>
					<div class='backgroundImage' />
					<div class='blur' />

					<div class='text'>
						<div class='topText'>About Us</div>
						<p>
							Cur heuretes resistere? Cum bromium unda, omnes exsules transferre altus, teres agripetaes.
							terror.
						</p>
						<div class='bottomText'>Sunt galataees pugna rusticus, grandis exemplares. </div>
					</div>
				</div>

				<div class='team'>
					<div class='title'>Meet Our Team</div>
					<div class='subtitle'>Here are some of the qualified technicians helping our clients excel.</div>
					<div class='pictures'>
						<div class='person'>
							<div class='picture'>
								<img
									src='https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80' />
							</div>
							<div class='name'>Some Person</div>
						</div>
						<div class='person'>
							<div class='picture'>
								<img
									src='https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80' />
							</div>
							<div class='name'>George Bedjanian</div>
						</div>
						<div class='person'>
							<div class='picture'>
								<img
									src='https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80' />
							</div>
							<div class='name'>Dvin Azizian</div>
						</div>
						<div class='person'>
							<div class='picture'>
								<img
									src='https://images.unsplash.com/photo-1544005313-94ddf0286df2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80' />
							</div>
							<div class='name'>No</div>
						</div>
					</div>
					<div class='bottomText'>
						<div class='title'>Get in touch with us for more information.</div>
						<Link href='/contactus' class='button getInTouch'>Get In Touch</Link>
					</div>
				</div>
			</div>
		);
	}

}