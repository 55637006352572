import { h } from 'preact';
import Mark from '../images/png/mark.png';
import { useEffect, useState } from 'preact/hooks';
import Header from '../components/Header';
import RhombusHardware from '../images/png/rhombus.png';

const quotes = [
	'I have been using Rhombus for my business already for 6 months, and i can tell that this is my favorite POS system by far, i am fully satisfied from Rhombus and PosXperts stuff.',
	'Elegant design, easy to use, great customer service, long story short go and get Rhombus, you will not regret !',
	'Great POS system...My sales representative is super helpful...I use this system for all 3 of my locations.',
];

const BestSeller = () => {
	const [quoteIndex, setIndex] = useState(0);

	useEffect(() => {
		setInterval(() => {
			setIndex((old) => {
				if (old < quotes.length - 1) return old + 1;
				return 0;
			});
		}, 7000);
	}, []);

	const quote = quotes[quoteIndex];

	return (
		<div class='bestseller'>
			<div class='headerExtension'>
				<Header />
				<img className='rhombusimage' src={RhombusHardware} alt='rhombus' />
				<div class='rhombus'>
					<h2>Rhombus</h2>
					<p>
						Whether you have a new or established business, Rhombus and our
						Xperts has a solution for you. Whether you are a small vendor with a
						kiosk, or have multiple store locations, Rhombus can accommodate
						your point-of-sale needs. Rhombus provides FREE software that
						connects to all your mobile devices and terminals. Rhombus offers a
						profitable and efficient POS solution. You can also have a customer
						display on the system which is optional. Modern POS software built
						for your business.
					</p>
				</div>
				<div class='feedbacks'>
					<h2>Some Feedback From Our Valued Customers</h2>
					<div class='shadow'>
						<img class='opeingmark' src={Mark} alt='image' />
						<p class='text'>{quote}</p>
						<img class='closingmark' src={Mark} alt='image' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default BestSeller;
