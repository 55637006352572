import { h, Component } from 'preact';
import { Router } from 'preact-router';

import Footer from './components/Footer';
import Home from './pages/Home';
import PointOfSale from './pages/PointOfSale';
import TechSupport from './pages/TechSupport';
import About from './pages/About';
import Contact from './pages/Contact';

import './stylus/index.styl';
import Hardware from './pages/Hardware';
import EPSG from './pages/EPSG';
import Redirect from './components/Redirect';
import BestSeller from './pages/BestSeller';
import DataManegment from './pages/DataManagement';

export default class App extends Component {
	render() {
		if (window.location.pathname === '/tv') {
			window.location.replace('https://nextcloud.hego.co/s/p8byqeRzNCEBY5B');
			return;
		}
		return (
			<div id='app'>
				<Router>
					<Home path='/' />
					<PointOfSale path='/pointofsale' />
					<Hardware path='/pointofsale/hardware' />
					<EPSG path='/pointofsale/merchantsolutions' />
					<TechSupport path='/techsupport' />
					<About path='/about' />
					<Contact path='/contact' />
					<BestSeller path='/bestseller' />
					<DataManegment path='/datamanegment' />
					<Redirect default to='/' />
				</Router>
				<Footer />
			</div>
		);
	}
}
