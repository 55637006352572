import { h, Component } from 'preact';
import { Link } from 'preact-router';
import Header from '../components/Header';

export default class Home extends Component {
	render() {
		return (
			<div class='homePage'>
				<div class='headerExtension'>
					<Header />
					<div class='text'>
						<div class='topText'>Point of Sale | Tech Support</div>
						<div class='bottomText'>
							The PosXperts team is ready to provide our customers with any
							technology services needed. We specialize in services such as
							Point of Sale and business grade technical support.
						</div>
						<div className='bottomText'>
							Call Us Now: <Link href='tel:+18004844955'>1-800-484-4955</Link>
						</div>
					</div>
				</div>
				<div class='services'>
					<div class='blocks'>
						<Link class='block' href='/pointofsale'>
							<div class='backgroundImage pointofsale' />
							<div class='content'>
								<div class='title'>Point of Sale Systems</div>
								<p>
									A effective and intuitive Point of Sale system is critical to
									a successful business operation. No one wants to spend time in
									front of a frozen computer screen, especially while a customer
									is waiting. Learn what PosXperts can do to bring your store to
									the modern era.
								</p>
								<div class='button clear learnMore'>Learn More</div>
							</div>
						</Link>
						<Link class='block' href='/techsupport'>
							<div class='backgroundImage techsupport' />
							<div class='content'>
								<div class='title'>Tech Support</div>
								<p>
									A proper IT infastructure is necessary for any business to
									excel. Our technicians and programmers will monitor your
									systems for any issues and insturtion attempts without
									distrupting your operations. Downtime can cost your business
									time and money, allow us to help you avoid it.
								</p>
								<div class='button clear learnMore'>Learn More</div>
							</div>
						</Link>
					</div>
				</div>
				<div class='why'>
					<div class='topTitle'>Don't waste time dealing with others.</div>
					<p class='topText'>
						PosXperts is your one stop shop for any residential, commercial or
						industrial projects. Our Xpert technicians can deal with any problem
						presented in a professional and timely manner. Save time and money
						working with a company that can handle all your needs in-house,
						without the need for expensive sub-contractors.
					</p>
					<div class='contactWrapper'>
						<Link class='button' href='/contact'>
							Contact Us
						</Link>
					</div>
				</div>
			</div>
		);
	}
}
