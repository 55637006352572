import { h, Component } from 'preact';
import { Link } from "preact-router";

export default class Footer extends Component {

	render() {
		return (
			<footer>
				<div class='footerImage'>
					<img src={require('../images/png/X.png')} alt='X Logo' />
					<div class='copyright '>© PosXperts</div>
				</div>
				<div class='footerContact'>
					<div class='phone'>
						<Link href='tel:+18004844955'>1-800-484-4955</Link>
					</div>
					<div class='address'>
						3250 Retail Dr. #120-104, Carson City, NV 89706
					</div>
				</div>
			</footer>
		);
	}

}