import { h, Component } from 'preact';
import { Link } from 'preact-router';
import Header from '../components/Header'

export default class PointOfSale extends Component {

	render() {
		return(
			<div class='pointofsalePage'>
				<div class='headerExtension'>
					<Header />
					<div class='blur' />

					<div class='text'>
						<div class='topText'>Modern Point of Sale Systems</div>
						<p>
							First impressions matter, especially to your customers. We can beat their expectations.
						</p>
					</div>
				</div>
				<div class='info'>
					<div class='text'>
						<div class='topTitle'>A simple to use modern interface.</div>
						<p>One of the most critical components to a happy customer is a efficient checkout. We will make sure your system is kept up-to-date with the latest technology and security, keeping your business running smoothly.</p>
					</div>
					<div class='blocks'>
						<div class='block'>
							<div class='backgroundImage partners' />
							<div class='title'>Partners</div>
							<p>We work with a large collection of software providers to match your business effectively.</p>
							{/*<div class='button clear learnMore'>Learn More</div>*/}
						</div>
						<Link href='/pointofsale/merchantsolutions' class='block'>
							<div class='backgroundImage merchant' />
							<div class='title'>Merchant Solutions</div>
							<p>Our terminals support all of the latest technology and standards, making sure your business is never behind.</p>
							<div class='button clear learnMore'>Learn More</div>
						</Link>
						<Link href='/pointofsale/hardware' class='block'>
							<div class='backgroundImage hardware' />
							<div class='title'>Hardware</div>
							<p>We use optimal hardware for our Point of Sale systems to make sure your business runs smoothly.</p>
							<div class='button clear learnMore'>Learn More</div>
						</Link>
					</div>
				</div>
				<div class='banner'>
					<p>Get in touch with us today for a easy quote.</p>
					<div class='buttonWrapper'>
						<a class='button' href='/contact'>Contact Us</a>
					</div>
				</div>
			</div>
		);
	}

}
